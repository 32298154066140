import styles from "./TradingviewChart.module.css";
import React, { useEffect, useRef } from "react";
import datafeed from "services/tradingview/datafeed";
import { widget } from "charting_library/";

export default function TradingviewChart({ widgetRef }) {
  const chart = useRef();
  const exchange = "binance";
  const fromSymbol = "eth";
  const toSymbol = "usdt";

  useEffect(() => {
    const widgetOptions = {
      symbol: `${exchange}:${fromSymbol}/${toSymbol}`,
      datafeed,
      interval: "15",
      container: chart.current,
      library_path: "charting_library/",
      locale: "en",
      disabled_features: [
        "header_symbol_search",
        "header_undo_redo",
        "header_compare",
      ],
      autosize: true,
      theme: "dark",
    };

    if (widget) {
      widgetRef.current = new widget(widgetOptions);
    }
  }, []);

  useEffect(() => {
    widgetRef.current.onChartReady(() => {
      widgetRef.current.setSymbol(
        `${exchange}:${fromSymbol}/${toSymbol}`,
        "15"
      );
    });
  }, [fromSymbol, exchange, toSymbol, widgetRef]);

  return <div ref={chart} className={styles.tradingview}></div>;
}

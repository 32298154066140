/* eslint-disable */
import TradingviewChart from "components/TradingviewChart/TradingviewChart";
import { useEffect, useRef, useState } from "react";
import styles from "./App.module.css";
import TradingviewWidget from "components/TradingviewWidget/TradingviewWidget";

function App() {
  const [bithumbPrice, setBithumbPrice] = useState();
  const [binancePrice, setBinancePrice] = useState();
  const [usdtPrice, setUsdtPrice] = useState();
  const widgetRef = useRef();

  useEffect(() => {
    const ws = new WebSocket("wss://pubwss.bithumb.com/pub/ws");

    ws.onopen = () => {
      console.log("ws open");
      const recent = {
        type: "ticker",
        symbols: [`ETH_KRW`, "USDT_KRW"],
        tickTypes: ["24H"],
      };
      ws.send(JSON.stringify(recent));
    };

    ws.onclose = (e) => {
      console.log("ws close", e.code);
    };

    ws.onerror = (err) => {
      console.log("ws error", err);
    };

    ws.onmessage = (message) => {
      const msg = JSON.parse(message.data);
      if (msg?.type === "ticker" && msg?.content.symbol.split("_")[0] === "ETH") {
        setBithumbPrice(msg.content.closePrice);
      } else if (msg?.type === "ticker" && msg?.content.symbol.split("_")[0] === "USDT") {
        setUsdtPrice(msg.content.closePrice);
      }
    };
  }, []);

  useEffect(() => {
    const ws = new WebSocket("wss://stream.binance.com:443/ws/stream");

    ws.onopen = () => {
      console.log("ws open");
      const recent = {
        method: "SUBSCRIBE",
        params: ["ethusdt@ticker"],
        id: 1,
      };
      ws.send(JSON.stringify(recent));
    };

    ws.onclose = (e) => {
      console.log("ws close", e.code);
    };

    ws.onerror = (err) => {
      console.log("ws error", err);
    };

    ws.onmessage = (message) => {
      const msg = JSON.parse(message.data);
      if (msg?.e === "24hrTicker") {
        setBinancePrice(Number(msg.c));
      }
    };
  }, []);

  return (
    <div className={styles.container}>
      <h1>ETH to the Mars</h1>

      <div className={styles.chart_container}>
        <TradingviewWidget />
        <TradingviewChart widgetRef={widgetRef} />
      </div>
      <div className={styles.tradingviewLicense}>
        <div>
          Ethmars' chart solution is provided by{" "}
          <a href="https://www.tradingview.com/" target="_blank">
            TradingView
          </a>
          , a global chart platform for the community.
        </div>
        <div>
          It provides not only charts like BTC/USD, but also advanced analysis tools like the
          <a href="https://www.tradingview.com/economic-calendar/" target="_blank">
            economic calender
          </a>
          or
          <a href="https://www.tradingview.com/screener/" target="_blank">
            stock screener
          </a>
          .
        </div>
      </div>
      <div className={styles.price_desc_container}>
        <div>
          Bithumb ETH Price:{" "}
          {bithumbPrice ? new Intl.NumberFormat().format(bithumbPrice) + " KRW " : "--"}
        </div>
        <div>
          Binance ETH Price:{" "}
          {binancePrice ? new Intl.NumberFormat().format(binancePrice) + " USDT " : "--"}
          {binancePrice && usdtPrice && (
            <>({new Intl.NumberFormat().format(binancePrice * usdtPrice)} KRW )</>
          )}
        </div>
        <div>USDT Price: {usdtPrice ? new Intl.NumberFormat().format(usdtPrice) : "--"}</div>
        <div>
          Kimchi Premium Percent:{" "}
          {binancePrice && bithumbPrice && usdtPrice && (
            <span className={styles.kimchi_premium_rate}>
              {(
                ((bithumbPrice - binancePrice * usdtPrice) / (binancePrice * usdtPrice)) *
                100
              ).toFixed(3)}
              %
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;

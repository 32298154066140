import { toCcxtTimeframe, toTradingViewResolution } from "./helpers";
import { subscribeOnStream, unsubscribeFromStream } from "./ccxtStremings";

const configurationData = {
  supports_search: false,
  supports_group_request: false,
  supports_marks: true,
  supports_timscale_marks: true,
  supports_time: true,
  supported_resolutions: ["15", "60", "1D", "1W"],
};

const datafeed = {
  onReady: (callback) => {
    setTimeout(() => callback(configurationData));
  },

  resolveSymbol: async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback,
    extension
  ) => {
    const exchange = symbolName.split(":")[0];
    const symbol = symbolName.split(":")[1];
    fetch(
      `https://dev-ccxt.ethmars.com/rest/${exchange}/${symbol.replace(
        "/",
        "_"
      )}/timeframes`
    )
      .then((res) => res.json())
      .then((info) => {
        const supportedResolutions = info.timeframes.map((timeframe) =>
          toTradingViewResolution(timeframe)
        );

        const symbolInfo = {
          ticker: symbolName,
          name: symbol,
          description: symbol,
          type: "crypto",
          session: "24x7",
          timezone: "Etc/UTC",
          exchange: exchange,
          minmov: 1,
          pricescale: Math.pow(10, info.scale + 1),
          has_intraday: true,
          visible_plots_set: "ohlcv",
          supported_resolutions: supportedResolutions,
          volume_precision: 2,
          data_status: "streaming",
          has_empty_bars: true,
        };

        setTimeout(() => {
          onSymbolResolvedCallback(symbolInfo);
        }, 0);
      });
  },

  getBars: async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) => {
    const { from, to } = periodParams;
    const ccxtResolution = toCcxtTimeframe(resolution);
    const fromSymbol = symbolInfo.name.split("/")[0];
    const toSymbol = symbolInfo.name.split("/")[1];

    fetch(
      `https://dev-ccxt.ethmars.com/rest/${
        symbolInfo.exchange
      }/${fromSymbol}_${toSymbol}/fetchOHLCV/${ccxtResolution}/${from * 1000}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data && data?.length === 0) {
          onHistoryCallback([], { noData: true });
          return;
        }
        let bars = [];
        data.forEach((bar) => {
          if (bar[0] / 1000 < to) {
            bars = [
              ...bars,
              {
                time: bar[0],
                open: bar[1],
                high: bar[2],
                low: bar[3],
                close: bar[4],
                volume: bar[5],
              },
            ];
          }
        });
        onHistoryCallback(bars, { noData: false });
      })
      .catch((err) => {
        console.log("[getBars]: Get error", err);
        onErrorCallback(err);
      });
  },

  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscriberUID,
    onResetCacheNeededCallback
  ) => {
    subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscriberUID,
      onResetCacheNeededCallback
    );
  },
  unsubscribeBars: (subscriberUID) => {
    unsubscribeFromStream(subscriberUID);
  },
};

export default datafeed;

export function toCcxtTimeframe(resolution) {
  switch (resolution) {
    case "1":
      return "1m";
    case "5":
      return "5m";
    case "15":
      return "15m";
    case "30":
      return "30m";
    case "60":
      return "1h";
    case "240":
      return "4h";
    case "1D":
      return "1d";
    case "1W":
      return "1w";
    case "1M":
      return "1M";
    default:
      break;
  }
}

export function toTradingViewResolution(timeframe) {
  switch (true) {
    case timeframe.includes("s"):
      return timeframe.toUpperCase();
    case timeframe.includes("m"):
      return String(parseInt(timeframe));
    case timeframe.includes("h"):
      return String(parseInt(timeframe) * 60);
    case timeframe.includes("d"):
      return timeframe.toUpperCase();
    case timeframe.includes("w"):
      return timeframe.toUpperCase();
    case timeframe.includes("M"):
      return timeframe;
    case timeframe.includes("y"):
      return String(parseInt(timeframe) * 12) + "M";
    default:
      console.log(
        "convert error in ccxt timeframe to tradingview resolution",
        timeframe
      );
      break;
  }
}

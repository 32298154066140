import { toCcxtTimeframe } from "./helpers";

const subscriptionCache = new Map();

const ws = new WebSocket("wss://dev-ccxt.ethmars.com");

ws.onopen = () => {
  console.log("kline ws open");
};

ws.onclose = (e) => {
  console.log("kline ws close", e.code);
};

ws.onerror = (err) => {
  console.log("kline ws error", err);
};

ws.onmessage = (message) => {
  const msg = JSON.parse(message.data);
  const subsId = `${msg.exchange}:${msg.ticker.toUpperCase()}.${msg.timeframe}`;
  const subscriptionItem = subscriptionCache.get(subsId);
  if (subscriptionItem === undefined) {
    return;
  }
  const bar = {
    time: msg.kline[0],
    open: msg.kline[1],
    high: msg.kline[2],
    low: msg.kline[3],
    close: msg.kline[4],
    volume: msg.kline[5],
  };
  subscriptionItem.handlers.forEach((handler) => handler.callback(bar));
};

//lastBar 삭제
export function subscribeOnStream(
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscriberUID,
  onResetCacheNeededCallback
) {
  const exchangeId = symbolInfo.exchange;
  const symbol = symbolInfo.name.toUpperCase();
  const timeframe = toCcxtTimeframe(resolution);
  const subsId = `${exchangeId}:${symbol}.${timeframe}`;
  const handler = {
    id: subscriberUID,
    callback: onRealtimeCallback,
  };
  let subscriptionItem = subscriptionCache.get(subsId);
  if (subscriptionItem) {
    subscriptionItem.handlers.push(handler);
    return;
  }
  subscriptionItem = {
    subscriberUID,
    resolution,
    handlers: [handler],
  };
  subscriptionCache.set(subsId, subscriptionItem);
  ws.send(
    JSON.stringify({
      event: "kline",
      data: {
        exchangeId,
        symbol,
        timeframe,
        since: 1,
      },
    })
  );
}

export function unsubscribeFromStream(subscriberUID) {
  for (const subsId of subscriptionCache.keys()) {
    const subscriptionItem = subscriptionCache.get(subsId);
    const idx = subscriptionItem.handlers.findIndex(
      (handler) => handler.id === subscriberUID
    );
    if (idx !== -1) {
      subscriptionItem.handlers.splice(idx, 1);
      if (subscriptionItem.handlers.length === 0) {
        subscriptionCache.delete(subsId);
      }
    }
  }
}
